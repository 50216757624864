import { NavLink } from "@remix-run/react";

const ErrorUnknownView = () => {
    return <div className="bg-gray-100 flex items-center justify-center h-dvh">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
            <svg className="mx-auto mb-4 w-12 h-12 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636a9 9 0 011.415 1.414M21 12a9 9 0 01-9 9 9 9 0 01-9-9 9 9 0 019-9 9 9 0 017.949 4.243M19.778 19.778a8.964 8.964 0 01-2.121 1.415M4.222 19.778a8.964 8.964 0 01-2.121-1.415M12 9v3m0 3h.01"></path></svg>
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Oops, something went wrong!</h1>
            <p className="text-gray-600 mb-6">{`We're sorry, but we can't complete your request right now. Please try again later.`}</p>
            <NavLink to="/" className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Go back home</NavLink>
        </div>
    </div>
}

export default ErrorUnknownView;