import { LinksFunction, MetaFunction, json } from "@remix-run/node";
import {
  Outlet,
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";

import globalStyles from "~/styles/output.css?url";
import Error404 from "./components/Errors/Error404";
import ErrorUnknownView from "./components/Errors/ErrorUnknown";
import ErrorTracer from "./components/Errors/ErrorTracer";
import notifyStyles from "react-toastify/dist/ReactToastify.css?url";

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Document from "./components/MuiDocument";
import { GlobalLoading } from "./components/GlobalLoadingIndicator";
import { APIProvider } from "@vis.gl/react-google-maps";
import MemoryStorageProvider from "./context/memoryContainer";
import { ToastContainer } from "react-toastify";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: globalStyles },
  { rel: "stylesheet", href: notifyStyles },
];

export const meta: MetaFunction = () => {
  return [
    { title: "Time2Staff" },
    {
      property: "og:title",
      content: "Time2Staff",
    },
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
    },
    {
      name: "description",
      content:
        "We are here for you if you are looking for a job or you are looking for staffThis website",
    },
  ];
};

export const loader = async () => {
  return json({
    ENV: {
      GOOGLE_MAPS_APIKEY: process.env.GOOGLE_MAPS_APIKEY ?? "",
      BASE_URL: process.env.BASE_URL ?? "",
    },
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20 * 60 * 1000,
    },
  },
});

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const data = useRouteLoaderData<typeof loader>("root");

  return (
    <Document>
      <GlobalLoading />
      <APIProvider apiKey={data?.ENV.GOOGLE_MAPS_APIKEY ?? ""}>
        <MemoryStorageProvider>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </MemoryStorageProvider>
        <ToastContainer position="top-center" />
      </APIProvider>
    </Document>
  );
};

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return <Error404 />;
  } else if (error instanceof Error) {
    return <ErrorTracer error={error} />;
  } else {
    return <ErrorUnknownView />;
  }
}

export default function App() {
  return <Outlet />;
}
