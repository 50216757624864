import { withEmotionCache } from "@emotion/react";
import {
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from "@remix-run/react";
import React from "react";
import { useStyleContext } from "~/context/clientStyleContext";
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/material';

type Props = {
  title?: string;
  children: React.ReactNode;
};

const Document = withEmotionCache(
  ({ children, title }: Props, emotionCache) => {
    const data = useRouteLoaderData<{ ENV: { GOOGLE_MAPS_APIKEY: string, BASE_URL: string } }>(
      "root"
    );
    const clientStyleData = useStyleContext();

    // Only executed on client
    useEnhancedEffect(() => {
      emotionCache.sheet.container = document.head;
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (emotionCache.sheet as any)._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData.reset();
    }, []);

    return (
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          {title ? <title>{title}</title> : null}
          <Meta />
          <Links />
          <meta
            name="emotion-insertion-point"
            content="emotion-insertion-point"
          />
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
            }}
          />
        </body>
      </html>
    );
  }
);

export default Document;
