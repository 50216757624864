type Props = {
    error: Error;
  };
  
  const ErrorTracer = ({ error }: Props) => {
    return (
      <div className="bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-md">
          <div className="mb-4">
            <h1 className="text-3xl font-bold text-red-600">
              Oops, an error occurred!
            </h1>
            <p className="text-gray-600">
              Something went wrong while trying to load this page.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Error Details</h2>
            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <pre className="text-red-500 text-wrap">
                <code id="error-message">{error.message}</code>
              </pre>
            </div>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Stack Trace</h2>
            <div className="bg-gray-100 p-4 rounded-lg shadow-inner overflow-x-auto">
              <pre className="text-gray-700">
                <code id="stack-trace">{error.stack}</code>
              </pre>
            </div>
          </div>
          <div className="text-center">
            <button className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Reload Page
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ErrorTracer;
  